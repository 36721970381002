import { FileOperationType } from "@shared/types";
import Document from "./Document";
import User from "./User";
import Model from "./base/Model";
import Relation from "./decorators/Relation";

class Event extends Model {
  static modelName = "Event";

  id: string;

  name: string;

  modelId: string | null | undefined;

  actorIpAddress: string | null | undefined;

  documentId: string;

  collectionId: string | null | undefined;

  @Relation(() => User)
  user: User;

  userId: string;

  @Relation(() => User)
  actor: User;

  actorId: string;

  @Relation(() => Document)
  document: Document | null | undefined;

  data: {
    name: string;
    email: string;
    title: string;
    published: boolean;
    templateId: string;
    serviceName: string;
    type: FileOperationType;
  };

  get model() {
    return this.name.split(".")[0];
  }

  get verb() {
    return this.name.split(".")[1];
  }

  get verbPastTense() {
    const v = this.verb;
    if (v.endsWith("e")) {
      return `${v}d`;
    }
    return `${v}ed`;
  }
}

export default Event;
